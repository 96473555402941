<template>
  <div class="ma-5">
    <v-toolbar>
      <v-toolbar-title>Cov-Cares Stoplight Survey</v-toolbar-title>
      <v-spacer></v-spacer>
      <span style="min-width:100px;text-align:right">{{ termLabel }}</span>
    </v-toolbar>
    <v-alert v-if="loading" type="info">Loading surveys...</v-alert>
    <v-alert v-else-if="courses.length === 0 && placements.length === 0" type="error">No surveys were found. We could not find any courses or ScotsWork placements assigned to you.</v-alert>
    <v-row v-else class="mt-2">
      <v-col v-if="courses.length > 0" :cols="12" :md="placements.length > 0 ? 6 : 10" :offset-md="placements.length > 0 ? 0 : 1" :lg="placements.length > 0 ? 6 : 8" :offset-lg="placements.length > 0 ? 0 : 2" :offset-xl="placements.length > 0 ? 0 : 3" xl="6">
        <v-card>
          <v-list>
            <v-subheader v-if="placements.length > 0">Courses</v-subheader>
            <v-list-item v-for="{ _id, title, studentCount, surveyDate } in courses" :key="_id" :to="'/cov-cares/stoplight/' + _id" class="survey-item">
              <v-list-item-content>
                <v-list-item-title>{{ title  }}</v-list-item-title>
                <v-list-item-subtitle>Students: {{  studentCount }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="surveyDate">Survey completed: {{ stringFormatDate(surveyDate) }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>No Stoplight Survey Submitted</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="surveyDate ? 'success' : 'info'">fa fa-{{ surveyDate ? 'check' : 'exclamation' }}-circle</v-icon>
                  </template>
                  <span v-if="surveyDate">Survey completed on {{ stringFormatDate(surveyDate) }}</span>
                  <span v-else>No Stoplight Survey Submitted</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col v-if="placements.length > 0" :cols="12" :md="courses.length > 0 ? 6 : 10" :offset-md="courses.length > 0 ? 0 : 1" :lg="courses.length > 0 ? 6 : 8" :offset-lg="courses.length > 0 ? 0 : 2" :offset-xl="courses.length > 0 ? 0 : 3" xl="6">
        <v-card>
          <v-list>
            <v-subheader v-if="courses.length > 0">ScotsWork Placements</v-subheader>
            <v-list-item v-for="{ _id, title, studentCount, surveyDate } in placements" :key="_id" :to="'/cov-cares/stoplight/' + _id" class="survey-item">
              <v-list-item-content>
                <v-list-item-title>{{ title  }}</v-list-item-title>
                <v-list-item-subtitle>Students: {{  studentCount }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="surveyDate">Survey completed: {{ stringFormatDate(surveyDate) }}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>No Stoplight Survey Submitted</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="surveyDate ? 'success' : 'info'">fa fa-{{ surveyDate ? 'check' : 'exclamation' }}-circle</v-icon>
                  </template>
                  <span v-if="surveyDate">Survey completed on {{ stringFormatDate(surveyDate) }}</span>
                  <span v-else>No Stoplight Survey Submitted</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.v-list-item.survey-item:not(:last-child) {
  border-bottom:1px solid #EEE
}
.v-list-item.survey-item.theme--dark:not(:last-child) {
  border-bottom:1px solid #333
}
</style>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const term = ref('')
    const termLabel = ref('')
    const courses = ref([])
    const placements = ref([])
    const loading = ref(true)

    onMounted(async () => {
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', 'stoplight.open': { $lte: new Date() }, 'stoplight.close': { $gte: new Date() } } })
      if (termData.length > 0) {
        term.value = termData[0].term
        termLabel.value = termData[0].label
      } else {
        alert('Not currently in a traditional term. Stoplight surveys are currently only available for the traditional terms.')
        return
      }

      const { data } = await root.$feathers.service('calendar/classes').find({ query: { term: term.value, 'instructors.pidm': user.value.pidm, 'counts.reg': { $gt: 0 }, $select: ['_id', 'term', 'crn', 'title', 'counts'], $limit: 50 } })
      const crns = []
      const arr = data.map(({ _id, term, crn, title, counts: { reg } }) => {
        crns.push(crn)
        return {
          _id,
          term,
          crn,
          title,
          surveyDate: null,
          studentCount: reg
        }
      })
      const { data: stoplightData } = await root.$feathers.service('cov-cares/stoplight').find({ query: { term: term.value, crn: { $in: crns } } })
      for (const { crn, createdAt, updatedAt } of stoplightData) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].crn === crn) {
            arr[i].surveyDate = new Date(updatedAt || createdAt)
            break
          }
        }
      }
      courses.value = arr
      await loadScotswork()
      loading.value = false
    })

    async function loadScotswork () {
      const aggregate = [
        { $match: { email: user.value.email } },
        {
          $lookup:
            {
              from: 'WorkStudy-Students',
              localField: 'code',
              foreignField: 'code',
              as: 'students',
              pipeline: [
                {
                  $match: {
                    aidy: '2425'
                  }
                }
              ]
            }
        },
        { $project: {
          title: '$name',
          studentCount: { $size: '$students' }
        } },
        { $match: { studentCount: { $gt: 0 } } }
      ]
      const { data } = await root.$feathers.service('work-study/placements').find({ query: { aggregate } })
      const search = data.map(({ title }) => title)
      const { data: stoplightData } = await root.$feathers.service('cov-cares/stoplight').find({ query: { term: term.value, placement: { $in: search } } })
      for (const { placement, createdAt, updatedAt } of stoplightData) {
        const index = data.findIndex(({ placement: p }) => p === placement)
        if (index >= 0) {
          data.splice(index, 1, { ...data[index], surveyDate: new Date(updatedAt || createdAt) })
        }
      }
      placements.value = data
    }

    return {
      user,
      term,
      termLabel,
      courses,
      placements,
      loading,
      stringFormatDate
    }
  }
}
</script>
